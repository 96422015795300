body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: #f4f4f4;
  color: #333;
}

.header {
  background: #5986b3;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.header h1 {
  margin: 0;
}

.about, .services, .experience, .testimonials {
  padding: 20px;
  background: #fff;
  margin: 20px auto;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

ul {
  list-style-type: disc;
  padding-left: 40px;
}

footer {
  text-align: center;
  padding: 20px;
  background: #333;
  color: #fff;
}

footer a {
  color: #5986b3;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
